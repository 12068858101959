$primary-color: #00abc8;

.external-first-container {
  padding-top: 150px;
}

.licencia-row {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
  cursor: pointer;
  margin-bottom: 10px;
}

.licencia-row i {
  padding-left: 18px;
  padding-right: 25px;
}

.accordion {
  overflow: hidden;
}

.accordion-section-content {
  display: none;
  padding: 5px 20px 0 45px;
  background-color: #fff;
  color: #7a7c7f;
}

a.white {
  color: white;
  text-decoration: underline;
  font-weight: bold;
}

a.white:hover {
  color: white;
  font-weight: bold;
  text-decoration: none;
}

#pago-inicial {
  margin-top: -40px;
  margin-bottom: 40px;
  font-size: 15px;
}

footer,
footer a {
  font-weight: bold;
}

select {
  background: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.05);
  height: 52px;
  font-size: 18px;
  line-height: 32px;
  color: #aaa;
  font-weight: 400;
  padding-left: 64px;
  width: 100%;
  vertical-align: top;
}

.min-section {
  min-height: 400px;
}

.exclusivo_dietistas {
  transform: rotate(-20deg);
}

.descuentos_estudiantes {
  transform: rotate(20deg);
}

.alert-container {
  margin-top: 89px;
  background-color: #d67e70;
  .external-alert {
    padding: 10px;
    color: white;
    text-align: center;
  }
}

#statistics {
  .percent {
    font-size: 28px;
    font-weight: bold;
  }
}

#app-dietistas {
  .media {
    border-radius: 25px;
    margin: 20px 10px;
  }
}

.red-text{
  color: red;
}
